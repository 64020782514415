<template>
    <button @click="show = true" class="btn ml-2 btn-success btn-sm">
        <i class="fa fa-upload"></i> Tải lên
        <b-modal v-model="show" title="Tải danh sách học viên" @close="close" no-close-on-esc no-close-on-backdrop
                 no-enforce-focus>
            <div class="form-group">
                <label><b>Tải xuống tập tin mẫu</b></label>
                <div>
                    <button @click="downloadTemplate" class="btn btn-sm btn-secondary mr-1 mb-2">
                        <i class="fa fa-file-excel"></i> {{templateName}}
                    </button>
                </div>
            </div>
            <div class="form-group">
                <label><b>Chọn tập tin</b></label>
                <b-form-file v-model="file" :disabled="reading||totalRows>0"
                             placeholder="Chọn tập tin từ máy tính hoặc kéo thả vào đây"
                             drop-placeholder="Chọn tập tin từ máy tính hoặc kéo thả vào đây"
                             browse-text="Chọn tập tin" 
                             accept="application/x-iwork-keynote-sffnumbers,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"></b-form-file>
            </div>
            <div class='mb-3' ></div>
            <div v-if="reading" class="alert alert-warning">
                <b-spinner small variant="primary" class='mr-1'></b-spinner>
                <span>Đang đọc file. Quá trình này có thể sẽ tốn nhiều thời gian nếu file tải lên có dung lượng lớn. Xin đừng tắt cửa sổ trình duyệt cho đến khi quá trình tải lên hoàn tất!</span>
            </div>
            <div v-if="importing" class="alert alert-warning">
                <b-spinner small variant="primary" class='mr-1'></b-spinner>
                <span>Đang tải dữ liệu lên... Xin hãy kiên nhẫn, việc tải lên sẽ mất một chút thời gian nếu file của bạn quá lớn</span>
            </div>
            <div class="mb-3" v-if="totalRows">
                <b-progress :max="totalRows">
                    <b-progress-bar :value="successCount+errorCount"
                                    :label="`${(((successCount + errorCount) / totalRows) * 100).toFixed(0)}%`"></b-progress-bar>
                </b-progress>
                <div class="text-center mt-2">Xử lý {{ successCount + errorCount }}/{{ totalRows }} dòng | Vấn đề: {{ errorCount }}
                    <a v-if='errorCount > 0' @click="downloadErrors" class="btn-link text-primary" href="javascript:;">[Chi tiết]</a>
                </div>
            </div>
            <template v-slot:modal-footer>
                <button class="btn btn-secondary" @click="cancel" v-if="!reading&&importing">Dừng tải</button>
                <button class="btn btn-primary" @click="reset" v-if="!reading&&!importing&&totalRows>0">Làm lại</button>
                <button v-if="totalRows===0" class="btn btn-primary" @click="process" :disabled="reading||importing">
                    <b-spinner small v-if="reading||importing"></b-spinner>
                    Tải lên
                </button>
            </template>
        </b-modal>
    </button>
</template>

<script>
    import Import from "@/mixins/Import";

    export default {
        props: ['type', 'params'],
        mixins: [Import],
        data() {
            return {
                reloadBus: 'reloadParameter',
                service: '/parameter',
                templateName: `parameter-${this.type}-template.xlsx`,
                headers: [
                    {header: 'position', key: 'position', label: 'Thứ tự', width: 15},
                    {header: 'code', key: 'code', label: 'Giá trị', width: 30},
                    {header: 'value', key: 'value', label: 'Nhãn', width: 100},
                    {header: 'note', key: 'note', label: 'Ghi chú', width: 30}
                ]
            }
        },
        methods: {
            getItem(headerRow, row) {
                let item = {
                    type: this.type,
                    status: 1
                };
                for (let j = 1; j <= headerRow.cellCount; j++) {
                    let key = headerRow.getCell(j).value;
                    let value;
                    if (row.getCell(j).isHyperlink) {
                        value = row.getCell(j).value.text;
                    } else {
                        value = row.getCell(j).value;
                    }
                    if (value) {
                        if (typeof value === 'string') {
                            value = value.trim();
                        }
                        if (key === 'code') {
                            value = `${value}`;
                        } else if (key === 'note') {
                            value = `${value}`;
                        }
                        item[key] = value;
                    }

                }
                return item;
            }
        }
    }
</script>
