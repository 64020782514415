<template>
    <div class="row">
        <div class="col-12">
            <g-loading v-if="firstLoading" />
            <g-table v-else :filter="filterFormModel" :isSelected="false" :itemCount="itemCount" :items="items" :fields="fields" 
                     @load="load" @reset="reset" :title="model.label" :titleIcon="model.icon">
                <template slot="actionBefore">
                    <b-tabs class="c-tabs-button mr-2" small pills v-model="filterFormModel.status" @input="load">
                        <b-tab title="Tất cả"></b-tab>
                        <b-tab title="Hoạt động"></b-tab>
                        <b-tab title="Tạm khoá"></b-tab>
                    </b-tabs>
                </template>
                <template slot="action">
                    <import :type='type' class='mr-1' />
                    <export :type='type' :filter="filterFormModel" :totalCount="itemCount" class='mr-1' />
                    <g-button size="sm" v-if="permissions('parameter_create')"  variant="success" icon="plus" content="Thêm mới" @click='$bus.$emit("createParameter")' />
                </template>
                <template #cell(value)="{item}">
                    <span :class="item.status == 1 ? 'c-signal is-success' : 'c-signal'">
                        <span class="h-word-break">{{item.value}}</span>
                    </span>
                    <br/>
                    <span v-if='item.note' class="small text-gray-999 h-word-break">{{item.note}}</span> 
                </template>
                <template #cell(time)="{item}">
                    <div><span class="small text-gray-999">Tạo ngày:</span> {{item.create_time * 1000 | datetimeFormat}}</div>
                    <div><span class="small text-gray-999">Cập nhật ngày:</span> {{item.update_time * 1000 | datetimeFormat}}</div>
                </template>
                <template #cell(action)="{item}">
                    <g-button v-if="permissions('parameter_update')" size="sm" variant="outline-info" class="ml-1" icon="pencil" @click='$bus.$emit("updateParameter", item.id)' />
                    <g-button v-if="permissions('parameter_del')" size="sm" variant="outline-danger" class="ml-1" icon="trash-fill" @click='$bus.$emit("delParameter", item.id)' />
                </template>
            </g-table>
        </div>
        <parameter-form :type="type" />
    </div>
</template>

<script>
    import list from "@/mixins/List";
    import ParameterForm from './ParameterForm';
    import Export from './ParameterExport';
    import Import from './ParameterImport';
    import role from '@/mixins/Role';
    export default {
        props: ['type'],
        mixins: [list,role],
        components: {ParameterForm, Export, Import},
        data: function () {
            return {
                service: '/parameter',
                fields: [
                    {key: 'code', label: "Giá trị", sortable: true, class: "text-center"},
                    {key: 'value', label: "Nhãn hiển thị", sortable: true},
//                    {key: 'time', label: "Thời gian"},
                    {key: 'position', label: "Thứ tự", sortable: true, class: "text-right"},
                    {key: 'action', label: "Hành động", class: 'text-right'}
                ],
                defaultFilterFormModel: {
                    type: this.type,
                    keyword: '',
                    status: 0
                }
            };
        },
        computed: {
            model() {
                return Object.values(this.$params.const).find(item => {
                    return item.key === this.type;
                });
            },
        },
        watch: {
            type: {
                handler(val) {
                    this.filterFormModel.type = val;
                    this.defaultFilterFormModel.type = val;
                    this.load();
                }, deep: true
            }
        },
        mounted: function () {
            this.$bus.$on('reloadParameter', this.load);
        },
        beforeDestroy: function () {
            this.$bus.$off('reloadParameter');
        },
    }
</script>