<template>
    <button class="btn btn-gray btn-sm mr-1 ml-1" @click="exportFile">
        <i class="fa fa-download icon-left"></i> Xuất dữ liệu
        <b-modal v-model="exporting" title="Đang tải dữ liệu" @close="close" no-close-on-esc no-close-on-backdrop no-enforce-focus>
            <div class="alert alert-warning">
                <b-spinner small variant="primary"></b-spinner>&nbsp;
                <span>Dữ liệu đang được tải xuống! Xin vui lòng đợi trong giây lát và xin đừng tắt cửa sổ này cho đến khi quá trình tải xuống hoàn tất.</span>
                {{downloadedCount}} - {{totalCount}}
                <b-progress :max="totalCount">
                    <b-progress-bar :value="downloadedCount" :label="`${((downloadedCount / totalCount) * 100).toFixed(0)}%`"></b-progress-bar>
                </b-progress>
            </div>
        </b-modal>
    </button>
</template>

<script>
    import Export from "@/mixins/Export";

    export default {
        props: ['type', 'filter', 'totalCount'],
        mixins: [Export],
        data() {
            return {
                service: '/parameter',
                fileName: `parameter_${this.type}`,
                headers: [
                    {header: 'position', key: 'position', label: 'Thứ tự', width: 15},
                    {header: 'code', key: 'code', label: 'Giá trị', width: 30},
                    {header: 'value', key: 'value', label: 'Nhãn', width: 100},
                    {header: 'note', key: 'note', label: 'Ghi chú', width: 30}
                ]
            }
        },
        methods: {
            addRow(item) {
                let row = {
                    position: item.position,
                    code: item.code,
                    value: item.value,
                    note: item.note,
                };
                return row;
            }
        }
    }
</script>