<template>
    <b-modal v-model="showForm" :title="formTitle" @ok="save" ok-title="Lưu" cancel-title="Huỷ" no-close-on-backdrop no-enforce-focus>
        <form-input label="Nhãn hiển thị" :model="formModel" :errors="formErrors" attribute="value" @change="changeValue" />
        <form-input :disabled="formModel.id" label="Giá trị" :model="formModel" :errors="formErrors" attribute="code" />
        <form-input label="Số thư tự" type="number" :model="formModel" :errors="formErrors" attribute="position" />
        <form-textarea label="Ghi chú" :model="formModel" :errors="formErrors" attribute="note" />
        <form-checkbox chk-label="Hoạt động" :model="formModel" attribute="status" :errors="formErrors" />
        <template v-slot:modal-footer="{ ok, cancel}">
            <b-button variant="secondary" class="float-right" @click="cancel">Đóng</b-button>
            <b-button :disabled="saveLoading" variant="primary" class="float-right" @click="ok">
                <b-spinner v-if="saveLoading" small></b-spinner>
                <em v-else class="fa fa-save" ></em>
                Lưu lại
            </b-button>
        </template>
    </b-modal>
</template>

<script>
    import crud from '@/mixins/CRUD';

    export default {
        props: ['type'],
        mixins: [crud],
        components: {},
        data: function () {
            return {
                service: '/parameter',
                reloadBus: 'reloadParameter',
                defaultFormModel: {
                    code: null,
                    value: null,
                    type: this.type,
                    position: 1,
                    status: 1
                }
            };
        },
        computed: {
            model() {
                return Object.values(this.$params.const).find(item => {
                    return item.key === this.type;
                });
            },
        },
        watch: {
            type: {
                handler(val) {
                    this.formModel.type = val;
                    this.defaultFormModel.type = val;
                }, deep: true
            },
            "formModel.code": {
                handler(val) {
                    if (val) {
                        this.formModel.code = this.$utils.createCode(val);
                    }
                }, deep: true
            }
        },
        methods: {
            changeValue() {
                if (this.formModel.value && !this.formModel.code) {
                    this.formModel.code = this.$utils.createCode(this.formModel.value);
                }
            },
        },
        mounted: function () {
            this.$bus.$on(`createParameter${this.type}`, this.create);
            this.$bus.$on('createParameter', this.create);
            this.$bus.$on('updateParameter', this.update);
            this.$bus.$on('delParameter', this.del);
        },
        beforeDestroy: function () {
            this.$bus.$off(`createParameter${this.type}`);
            this.$bus.$off('createParameter');
            this.$bus.$off('updateParameter');
            this.$bus.$off('delParameter');
        },
    }
</script>